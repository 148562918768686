$enable-ltr: true; /* stylelint-disable-line scss/dollar-variable-default */
$enable-rtl: true; /* stylelint-disable-line scss/dollar-variable-default */

// Import styles
@import "@coreui/coreui-pro/scss/coreui";
@import "@coreui/coreui-pro/scss/themes/dark";

@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
// utils
@import "utils/index";

//bases
@import "bases/index";

//fonts
@import "fonts/index";

@import "layout";
