html {
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
  font-size: 16px;
}
body {
  font-family: "Be Vietnam Pro" !important;
  color: $color-black !important;
}
